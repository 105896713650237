<template>
    <v-data-table
        :headers="headers"
        :items="loading ? [] : items"
        :items-per-page="itemsPerPage"
        :hide-default-footer="hideDefaultFooter"
        :sort-by="sortByHeaders"
        :sort-desc="sortByDesc"
        class="elevation-0 reports__table"
        :loading="loading"
        height="100%"
    >
        <template v-slot:item.evaluation_type="{ item }">
            <span>
                {{ getEvaluationType(item.evaluationType) }}
            </span>
        </template>

        <template v-slot:item.evaluation_state="{ item }">
            <v-tooltip right v-if="item.evaluation_state === 'COMPLETED'" color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" pill color="green darken-1">
                        <v-icon color="white" small>
                            mdi-clipboard-check
                        </v-icon>
                    </v-chip>
                </template>
                <span class="black--text">{{ $t("evaluate.texts.tooltips.diagnosesState.completed") }}</span>
            </v-tooltip>

            <v-tooltip right v-else-if="item.evaluation_state === 'IN_PROGRESS'" color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" pill color="grey darken-1">
                        <v-icon color="white" small>
                            mdi-clipboard-minus
                        </v-icon>
                    </v-chip>
                </template>
                <span class="black--text">{{ $t("evaluate.texts.tooltips.diagnosesState.inProgress") }}</span>
            </v-tooltip>

            <span v-else>
                -
            </span>
        </template>

        <template v-slot:item.status="{ item }">
                <span
                    v-if="item.status != null || item.students.length > 0"
                    class="d-flex flex-column align-center justify-center position-relative"
                    data-cy="table-bookProgress-span"
                >
                    <div class="w-100 d-flex flex-row align-center justify-center position-relative ma-0 pa-0">
                        {{
                            item.students.length > 0
                                ? ((item.students.filter(
                                      (stu) => stu.diagnosis && stu.diagnosis?.progress_state === 'COMPLETED'
                                  ).length /
                                      item.students.length) *
                                  100).toFixed(0)
                                : 0
                        }}%
                        <v-progress-linear
                            class="mx-3"
                            :value="
                                item.students
                                    ? (item.students.filter(
                                          (stu) => stu.diagnosis && stu.diagnosis?.progress_state === 'COMPLETED'
                                      ).length /
                                          item.students.length) *
                                      100
                                    : 0
                            "
                            color="primary"
                            rounded
                            height="10px"
                        ></v-progress-linear>
                    </div>
                    <span class="ma-0 pa-0 caption align-self-end grey--text font-weight-light">{{item.students.filter((stu) => stu.diagnosis && stu.diagnosis?.progress_state === 'COMPLETED').length}}/{{item.students.length}}</span>
    
                </span>
                <span v-else class="text-center"> - </span>
        </template>

        <template v-slot:item.progress_status="{ item }">
                <span
                    v-if="item.progress_status != null || item.students.length > 0"
                    class="d-flex flex-column align-center justify-center position-relative"
                    data-cy="table-bookProgress-span"
                >
                    <div class="w-100 d-flex flex-row align-center justify-center position-relative ma-0 pa-0">
                        {{
                            item.students.length > 0
                                ? ((item.students.filter(
                                      (stu) => stu.progress_state === 'COMPLETED'
                                  ).length /
                                      item.students.length) *
                                  100).toFixed(0)
                                : 0
                        }}%
                        <v-progress-linear
                            class="mx-3"
                            :value="
                                item.students
                                    ? (item.students.filter(
                                          (stu) => stu.progress_state === 'COMPLETED'
                                      ).length /
                                          item.students.length) *
                                      100
                                    : 0
                            "
                            color="primary"
                            rounded
                            height="10px"
                        ></v-progress-linear>
                    </div>
                    <span class="ma-0 pa-0 caption align-self-end grey--text font-weight-light">{{item.students.filter((stu) => stu.progress_state === 'COMPLETED').length}}/{{item.students.length}}</span>
    
                </span>
                <span v-else class="text-center"> - </span>
        </template>

        <template v-slot:item.course_name="{ item }">
            <v-chip label color="deep-purple lighten-5" class="px-9 my-3">
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small> mdi-folder </v-icon>
                    <span
                        class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium"
                    >
                        {{ item.course_name }}
                    </span>
                </v-row>
            </v-chip>
        </template>

        <template v-slot:item.created_at="{ item }">
            <v-chip label color="deep-purple lighten-5" class="px-9 my-3">
                <v-row align-content="center" justify="start">
                    <v-icon color="deep-purple" class="mr-2" small> mdi-calendar </v-icon>
                    <span
                        class="body-2 deep-purple--text lighten-5 text-capitalize font-weight-medium"
                    >
                        {{ item.created_at.slice(0, 10) }}
                    </span>
                </v-row>
            </v-chip>
        </template>

        <!-- Actions -->
        <template v-slot:item.id="{ item }" v-if="showActions">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <!-- Evaluate -->
                <SimpleButton
                    v-if="showEvaluate"
                    icon="mdi-file-check-outline"
                    :text="$t('evaluate.actions.evaluate')"
                    @click="handleEvaluation(item.id)"
                    rounded
                />
                <!-- Stats -->
                <v-btn
                    v-if="showDetail"
                    icon
                    elevation="0"
                    class="primary ma-1 pa-3"
                    small
                    @click="goToStats(item)"
                >
                    <v-icon small class="pa-3" color="white">mdi-poll</v-icon>
                </v-btn>

                <!-- Delete -->
                <v-btn
                    icon
                    elevation="0"
                    class="red lighten-5 ma-1 pa-3"
                    small
                    @click="deleteBookAssigment(item)"
                >
                    <v-icon small class="pa-3" color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import SimpleButton from '@/components/core/buttons/simpleButton'

export default {
    name: 'AssignedEvaluationsTable',
    components: {
        SimpleButton,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
            required: false,
        },
        showEvaluate: {
            type: Boolean,
            default: false,
            required: false,
        },
        showActions: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    methods: {
        getEvaluationType(evaluationType) {
            switch (evaluationType) {
                case 'INITIAL':
                    return this.$t('evaluate.diagnosis.types.initial')
                case 'MIDDLE':
                    return this.$t('evaluate.diagnosis.types.middle')
                case 'FINAL':
                    return this.$t('evaluate.diagnosis.types.final')
            }
        },
        goToStats(evaluation) {
            this.$emit('on-stats', evaluation)
        },
        goToModify(evaluation) {
            this.$emit('on-update', evaluation)
        },
        deleteBookAssigment(evaluation) {
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('evaluate.actions.confirmation.delete'),
                async () => {
                    await this.$emit('on-delete', evaluation)
                }
            )
        },
        handleEvaluation(evaluationID) {
            this.$emit('on-evaluation', evaluationID)
        },
    },
}
</script>

<style scope lang="scss">
@import '../../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.reports__table .v-data-table-header {
    background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}
.evaluation-option {
    cursor: pointer;
}

.text-no-wrap {
    word-break: keep-all;
}
</style>
