<template>
    <v-btn
        :color="color"
        :loading="loading"
        :class="`text-align-start d-flex align-items-start justify-content-start py-3 ${fullWidth ? 'w-100' : ''}`"
        elevation="0"
        :disabled="disabled"
        @click="handleClick"
    >
        <v-row align-content="center" :justify="!textCenter ? 'start' : 'center'" class="text-start pa-3">
            <v-icon v-if="icon" small class="mr-1">
                {{ icon }}
            </v-icon>
            <span class="body-2 text-lowercase first-letter-uppercase font-weight-medium">
                {{ text }}
            </span>
        </v-row>
    </v-btn>
</template>

<script>
export default {
    name: 'SimpleButton',
    props: {
        text: {
            type: String,
            default: 'Click here!',
            required: true
        },
        icon: {
            type: String,
            default: 'mdi-clipboard-check-multiple',
            required: false
        },
        color: {
            type: String,
            default: 'primary',
            required: false
        },
        loading: {
            type: Boolean,
            default: false,
            required: false
        },
        fullWidth: {
            type: Boolean,
            default: false,
            required: false
        },
        textCenter: {
            type: Boolean,
            default: false,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    methods: {
        handleClick() {
            this.$emit('click')
        }
    }
}
</script>