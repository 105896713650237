<template>
    <v-container class="ma-0 pa-0">
       
            <v-btn
                color="grey lighten-1"
                outlined
                rounded
                elevation="0"
                small
                class="text-capitalize"
                @click="handleGoBackToAssignedEvaluations"
            >
                <v-icon class="mr-1" color="grey lighten-1" small>mdi-arrow-left</v-icon>
                <span class="grey--text font-weight-semibold">
                    {{ $t('library.goBack') }}
                </span>
            </v-btn>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">Informacion General</h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <AssignedEvaluationsTable
                    :headers="assignedEvaluationsHeaders"
                    :items="assignedEvaluationsItems"
                    @on-stats="onStatsEvaluation"
                    @on-delete="onDeleteEvaluation"
                    :loading="loading"
                />
            </section>
        </v-card>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">Listado de estudiantes</h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <EvaluationStudentListTable
                    :headers="studentListHeaders"
                    :items="studentList"
                    @on-evaluate="onEvaluate"
                    :loading="loading"
                    @on-stats="handleOnStatsStudentResult"
                    @on-delete="handleOnDeleteStudentResult"
                />
            </section>
        </v-card>
    </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import AssignedEvaluationsTable from '../shared/tables/AssignedEvaluationsTable.vue'
import EvaluationStudentListTable from '../shared/tables/EvaluationStudentList.vue'
export default {
    name: 'Evalaute',
    components: {
        SimpleButton,
        SimpleSelect,
        AssignedEvaluationsTable,
        EvaluationStudentListTable
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            currentEvaluation: (state) => state.fluency.currentAssignedEvaluationDetail,
            userID: (state) => state.userData.user.id,
        }),
        ...mapGetters({
            evaluationDetail: 'fluency/currentDiagnostic',
        }),
        assignedEvaluationsHeaders() {
            return [
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedText'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedTo'),
                    align: 'center',
                    sortable: false,
                    value: 'course_name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.progress'),
                    align: 'center',
                    sortable: false,
                    value: 'status',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.startDate'),
                    align: 'center',
                    sortable: false,
                    value: 'created_at',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        assignedEvaluationsItems() {
            return [this.currentEvaluation]
        },
        studentListHeaders() {
            return [
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.state'),
                    align: 'center',
                    sortable: false,
                    value: 'progress_state',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        studentList() {
            return this.currentEvaluation?.students || []
        },
       
    },
    methods: {
        ...mapActions('fluency', [
            'getDiagnosisDetail',
            'deleteEvaluationAssignment',
            'deleteStudentDiagnosis'
        ]),
        onEvaluate(studentID) {
            this.$router.push(`/teacher/evaluations/evaluate/${this.currentEvaluation.id}/student/${studentID}`) 
        },
        async getDiagnosis() {
            try {
                this.loading = true
                await this.getDiagnosisDetail({
                    diagnosisID: this.$route.params.assignmentId,
                    teacherID: this.userID,
                })
            } catch (error) {
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        async onDeleteEvaluation(evaluation) {
            try {
                this.loading = true
                await this.deleteEvaluationAssignment({evaluationID: evaluation.id})
                this.$router.push('/teacher/evaluations/assignments')
            } catch (error) {
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        handleGoBackToAssignedEvaluations() {
            this.$router.push('/teacher/evaluations/assignments')
        },
        async handleOnDeleteStudentResult(studentDiagnosis) {
            try {
                this.loading = true
                await this.deleteStudentDiagnosis({resultsID: studentDiagnosis.id})
                await this.getDiagnosis()
            } catch (error) {
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        handleOnStatsStudentResult(student) {
            this.$router.push(`/teacher/evaluations/evaluate/${this.currentEvaluation.id}/student/${student.id}`) 
        },

    },
    async beforeMount() {
        this.loading = true
        await this.getDiagnosis()
        this.loading = false
    },
}
</script>
